import React, { useEffect, useRef, useState } from 'react';
import './header.css';
import { BrowserRouter, Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/routes.constants';
import gsap from 'gsap';
import Power3 from 'gsap';
import { useNavigate } from 'react-router';

export const Header = (props) => {

  const header = useRef();
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState('');

  const location = useLocation();

  useEffect(() => {
    const loco = location.pathname.split('/');
    const current = loco[loco.length - 1];
    setCurrentLocation(current);
  }, [location]);

  useEffect(() => {

    if (props.clicked) {
      gsap.to(header.current, { opacity: 1, duration: 3, delay: 0.5, ease: Power3.easeInOut });

    }

  }, [props.clicked]);

  const goTo = (path) => {
    if (!props.mainTextTransition && !props.switchPage) {
      const locationSplitted = window.location.href.split('/');
      const currentLocation = '/' + locationSplitted[locationSplitted.length - 1];

      if (currentLocation === path) {
        return;
      }

      props.setSwitchPage(true);
      setTimeout(() => {
        props.setShowCases(false);
        navigate(path);
        props.setSwitchPage(false);
      }, 2000);
    }
  };

const onMouseOver = () => {
  props.setCursorState((state) => state = 'cursor-hover');
};

const onMouseOut = () => {
  props.setCursorState((state) => state = '');
};


return (
  <div ref={header}  className={'header ' + `${props.clicked ? '' : 'no-point-event'}`}>
    <a onClick={() => {
      goTo(ROUTES.Main);
    }}
       onMouseOver={onMouseOver}
       onMouseOut={onMouseOut}
    >
      <img className='logo' src='/images/logo.svg' alt='LOGO' />
    </a>

    <div className='navigation '>
      <div>
        <a className={'navigation_home link--kale'} onMouseOver={onMouseOver}
           onMouseOut={onMouseOut} style={{ fontWeight: currentLocation === '' ? 600 : 400 }} onClick={() => {
          goTo(ROUTES.Main);
        }}>
          HOME
        </a>
        <a className='link--kale' onMouseOver={onMouseOver}
           onMouseOut={onMouseOut} style={{ fontWeight: currentLocation === 'works' ? 600 : 400 }} onClick={() => {
          goTo('/works');
        }}>
          WORKS
        </a>
        <a className='link--kale' onMouseOver={onMouseOver}
           onMouseOut={onMouseOut} target='_blank' href="https://twitter.com/Third_dm_3d?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
          TWITTER
        </a>
      </div>
      <a className='link--kale'
         onMouseOver={onMouseOver}
         onMouseOut={onMouseOut} style={{ fontWeight: currentLocation === 'contact' ? 600 : 400 }} onClick={() => {
        goTo('/contact');
      }}>CONTACT
      </a>

    </div>
  </div>
);
}
;
