export const CASESCONST = [

    {
        name: 'Empathy Labs',
        textInfo: ['The Goal - help the company launch globally and make the innovative presence that makes them stand out of competitors'],
        aboutClientInfo: ['“At Empathy Labs, we believe in a radical idea – putting humans first. Technology should boost people, not replace them. Our ‘human-in-the-loop’ model ensures tech evolves with people.” - Mohammed Hannan, founder of Empathy Labs'],
        quote: '',
        videoUrl: null,
        link: 'https://empathylabs.co.uk/',
        casesImages: [
            './images/cases/empathy/1',
            './images/cases/empathy/2',
            './images/cases/empathy/3',
            './images/cases/empathy/4',
            './images/cases/empathy/5',
            './images/cases/empathy/6',
            './images/cases/empathy/7',
            './images/cases/empathy/8']
    },

    {
        name: 'Hailstone Labs',
        textInfo: ['The Goal - create an innovative presence to build trust and respect from web3 and tech companies.'],
        aboutClientInfo: ['Asia\'s leading Web 3 venture studio. We incubate and advise game-changing products'],
        quote: '',
        videoUrl: null,
        link: 'https://hailstonelabs.third-dimension.studio/',
        casesImages: [
            './images/cases/hailstone/1',
            './images/cases/hailstone/2',
            './images/cases/hailstone/3',
            './images/cases/hailstone/4',
            './images/cases/hailstone/5',
            './images/cases/hailstone/6',
            './images/cases/hailstone/7',
            './images/cases/hailstone/8',
            './images/cases/hailstone/9',
            './images/cases/hailstone/10',
        ]
    },

    {
        name: 'Miller Panelling',
        textInfo: ['The goal of the project is to show premium quality service for b2b domain and to show apple-styled animations for the construction field'],
        aboutClientInfo: ['“As seasoned builders, we work closely with end users and general contractors alike to develop tailored solutions that meet unique project needs.” - Stephanie Miller, President', 'Miller Panelling - premier specialty contractor lies a dedication to providing high-quality solutions. As seasoned builders, we work closely with end users and general contractors alike to develop tailored solutions that meet unique project needs.'],
        quote: '',
        videoUrl: null,
        link: 'https://m-panelling.vercel.app/products',
        casesImages: [
            './images/cases/miller/1',
            './images/cases/miller/2',
            './images/cases/miller/3',
            './images/cases/miller/4',
            './images/cases/miller/5',
            './images/cases/miller/6',
            './images/cases/miller/7',
            './images/cases/miller/8',
            './images/cases/miller/9',
            './images/cases/miller/10',
            './images/cases/miller/11',
            './images/cases/miller/12',
            './images/cases/miller/13',
            './images/cases/miller/14',
            './images/cases/miller/15',
        ]
    },

    {
        name: 'PB Media',
        textInfo: ['The Goal - create the most memorable and engaging experience in the domain. The company had a goal to be top of mind in the target market.'],
        aboutClientInfo: ['Make content that is actually worthy of the products you have created. Most brand videos either drive results or tell a story. We aim to do both.'],
        quote: '',
        videoUrl: null,
        link: 'https://pbmedia.third-dimension.studio/',
        casesImages: [
            './images/cases/pb/1',
            './images/cases/pb/2',
            './images/cases/pb/3',
            './images/cases/pb/4',
            './images/cases/pb/5',
            './images/cases/pb/6',
            './images/cases/pb/7',
            './images/cases/pb/8',
            './images/cases/pb/9',
        ]
    },



    {
        name: 'All In Motion',
        textInfo: ['The Goal - create a new brand and modern web presence for a motion design studio that works globally'],
        aboutClientInfo: ['All in Motion is a global creative production company that serves leading businesses and brands across the world to meet their communications needs.', 'The team provides a broad spectrum of solutions from bespoke deliveries to integrated campaigns thanks to an award-winning, involved in-house team.'],
        quote: '',
        videoUrl: null,
        link: 'https://all-in-motion-new-versiiion.webflow.io/',
        casesImages: [
            './images/cases/allinmotion/1',
            './images/cases/allinmotion/2',
            './images/cases/allinmotion/3',
            './images/cases/allinmotion/4',
            './images/cases/allinmotion/5',
            './images/cases/allinmotion/6',
            './images/cases/allinmotion/7',
            './images/cases/allinmotion/8',
            './images/cases/allinmotion/9',
            './images/cases/allinmotion/10',
            './images/cases/allinmotion/11',
            './images/cases/allinmotion/12',
        ]
    },



    {
        name: 'Byte Trading',
        textInfo: ['In cooperation with DAAAMN creative production studio, we were engaged to creation of 3D interactive explainer for tech company.', 'Our team were needed to provide stable nice experience among devices and provide high speed of load.'],
        aboutClientInfo: ['Byte Trading is a technology company building a derivatives native technology stack for better pricing and risk management of positions.'],
        quote: '“We build high-speed risk management systems that seamlessly deal with any market environment.”',
        videoUrl: null,
        link: 'https://bytetrading.third-dimension.studio',
        casesImages: [
            './images/cases/byte/1',
            './images/cases/byte/2',
            './images/cases/byte/3',
            './images/cases/byte/4',
            './images/cases/byte/5',
            './images/cases/byte/6',
            './images/cases/byte/7',
            './images/cases/byte/8']
    },



    {
        name: 'Somvai',
        textInfo: ['The Goal - build trust in the team and show key product features for the Kickstarter campaign.'],
        aboutClientInfo: ['R&D team of dreamer who’s goal - build innovative devices that help people well being'],
        quote: '',
        videoUrl: null,
        link: 'https://somvai.com/',
        casesImages: [
            './images/cases/somvai/1',
            './images/cases/somvai/2',
            './images/cases/somvai/3',
            './images/cases/somvai/4',
            './images/cases/somvai/5',
            './images/cases/somvai/6',
            './images/cases/somvai/7',
            './images/cases/somvai/8',
            './images/cases/somvai/9',
            './images/cases/somvai/10',
            './images/cases/somvai/11',
            './images/cases/somvai/12',
            './images/cases/somvai/13',
            './images/cases/somvai/14',
            './images/cases/somvai/15',
        ]
    },

    {
        name: 'Altra',
        textInfo: ['The goal of the project is to tokenize real estate property amongst other goods such as forests or wind turbines.', 'Users will be able to invest in a wide variety of products with a relatively small entry fee. The company will offer opportunities in markets that would otherwise be inaccessible.'],
        aboutClientInfo: ['CoinsPaid is an organisation providing cryptocurrency payment services and personal wallets. The company enables customers to operate worldwide, decrease costs and reach new markets whilst using their reliable cryptocurrency processing services and the wallet app.'],
        quote: '',
        videoUrl: null,
        worksTitle: '3D tokenized city immersive experience.',
        link: 'https://altra.third-dimension.studio/',
        casesImages: [
            './images/cases/altra/1',
            './images/cases/altra/2',
            './images/cases/altra/3',
            './images/cases/altra/4',
            './images/cases/altra/5',
            './images/cases/altra/6',
            './images/cases/altra/7',
            './images/cases/altra/8',
            './images/cases/altra/9',
            './images/cases/altra/10',
            './images/cases/altra/11']
    },

    {
        name: 'Corvus Research',
        textInfo: ['The team wanted to launch the company website in the format of an interactive scene. The design was supposed to be related to mathematics and computing.', 'The main goal was to attract new engineers and provide detailed information about tech.'],
        aboutClientInfo: ['Corvus Research is a technology company developing cutting-edge statistical models, alternative data sources, and machine learning for quantitative finance.', 'Their mission is to build a fully automated machine for finding predictive signals in a variety of markets.'],
        quote: '',
        videoUrl: './videos/corvus_bg',
        link: 'https://corvus.third-dimension.studio/',
        casesImages: [
            './images/cases/corvus/1',
            './images/cases/corvus/2',
            './images/cases/corvus/3',
            './images/cases/corvus/4',
            './images/cases/corvus/5',
            './images/cases/corvus/6',
            './images/cases/corvus/7',
            './images/cases/corvus/8',
            './images/cases/corvus/9']
    },

    {
        name: '3D City',
        textInfo: ['This website was developed for 3D production studio. The goal was to show the services in an immersive way. The concept is based on different districts with idea of clients segments.'],
        aboutClientInfo: ['Third Dimension studio is a web design studio that provides tech companies and brands around the world with interactive web experiences and immersive explainers.'],
        quote: '',
        videoUrl: null,
        link: 'https://city.third-dimension.studio',
        casesImages: [
            './images/cases/city/1',
            './images/cases/city/2',
            './images/cases/city/3',
            './images/cases/city/4',
            './images/cases/city/5',
            './images/cases/city/6',
            './images/cases/city/7',
            './images/cases/city/8',
            './images/cases/city/9',
            './images/cases/city/10']

    },

    // {
    //     name: 'Headphones',
    //     textInfo: ['This demo case is purposed to show all posibilities of WebGL tech to create 3D interactive landing pages.', 'Here we shown all features of the device from the best angles to achieve maximum of engagement.'],
    //     aboutClientInfo: ['Third Dimension studio is a web design studio that provides tech companies and brands around the world with interactive web experiences and immersive explainers.'],
    //     quote: '',
    //     videoUrl: null,
    //     link: 'https://headphones.third-dimension.studio/',
    //     casesImages: [
    //         './images/cases/headphones/1',
    //         './images/cases/headphones/2',
    //         './images/cases/headphones/3',
    //         './images/cases/headphones/4',
    //         './images/cases/headphones/5',
    //         './images/cases/headphones/6',
    //         './images/cases/headphones/7',
    //         './images/cases/headphones/8']
    // },





]
