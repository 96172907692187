import backgroundVertexShader from './shaders/vertex.js'
import backgroundFragmentShader from './shaders/fragment.js'
import {shaderMaterial} from "@react-three/drei";
import {extend, useFrame, useThree} from "@react-three/fiber";
import * as THREE from 'three'
import {useEffect, useRef} from "react";
import gsap from 'gsap';
import {WORKSCONST} from "../../constants/works.constant";

const BackgroundMaterial = shaderMaterial(
  {
    uTime: 0,
    uColorFirst: new THREE.Color(WORKSCONST[0].bgColor),
    uColorMain: new THREE.Color(WORKSCONST[0].bgSecondColor),
    uResolution: new THREE.Vector2(),
    uVisibility: 1.0,
    uPixelRatio: window.devicePixelRatio
  },
  backgroundVertexShader,
  backgroundFragmentShader
)


extend({BackgroundMaterial})

export default function Background(props) {
  const backgroundMaterial = useRef()


  useEffect(() => {
    backgroundMaterial.current.uResolution = new THREE.Vector2(props.windowDimensions.width, props.windowDimensions.height);
  }, [props.windowDimensions])

  useEffect(() => {
    props.setBackgroundRef(backgroundMaterial);
  }, [])

  const {width, height} = useThree((state) => state.viewport)

  const isFirstRun = useRef(true);
  useEffect(() => {
    let color = new THREE.Color(WORKSCONST[props.workIndex].bgColor);
    let secondColor = new THREE.Color(WORKSCONST[props.workIndex].bgSecondColor);



    if (isFirstRun.current) {
      isFirstRun.current = false;
      gsap.to(backgroundMaterial.current.uColorFirst, {r: color.r ,g: color.g,b:  color.b, duration: 0})
      gsap.to(backgroundMaterial.current.uColorMain, {r: secondColor.r ,g: secondColor.g,b:  secondColor.b, duration: 0})

      return
    }
    gsap.to(backgroundMaterial.current.uColorFirst, {r: color.r ,g: color.g,b:  color.b, duration: 0.5, ease: 'none'})
    gsap.to(backgroundMaterial.current.uColorMain, {r: secondColor.r ,g: secondColor.g,b:  secondColor.b, duration: 0.5, ease: 'none'})

  }, [props.workIndex])

  useFrame((state, delta) => {
    backgroundMaterial.current.uTime += delta
  })


  return <mesh position-z={-50}>
    <planeGeometry args={[width * 5, height * 5, 1, 1]}/>
    <backgroundMaterial ref={backgroundMaterial}/>
  </mesh>
}
