import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CASESCONST } from '../../constants/cases.constant';
import './cases.css';
// import SplitType from 'split-type';
import {SplitText} from "../../libs/SplitText";

import gsap from 'gsap';
import { ScrollContext } from '../../Context/ScrollContext';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useLocation } from 'react-router-dom';
import { WORKSCONST } from '../../constants/works.constant';
import { useGSAP } from '@gsap/react';
import { Helmet } from 'react-helmet';


const TopSection = (props) => {
  const caseTopContainer = useRef();
  const scrollForMore = useRef();
  const caseDescriptions = useRef([]);
  const caseTitle = useRef([]);
  const [opacityDelay, setOpacityDelay] = useState(true);
  const [scrollContext] = useContext(ScrollContext);
  const [firstLaunch, setFirstLaunch] = useState(true);

  useEffect(() => {
    if (!props.transition || !firstLaunch) {
      const offset = scrollContext.scrollTop / scrollContext.scrollAll;
      const range = (from, distance) => {
        const start = from;
        const end = start + distance;
        return offset < start ? 0 : offset > end ? 1 : (offset - start) / (end - start);
      };
      const range1 = range(0 / 20, 1 / 20);
      caseTopContainer.current.style.opacity = THREE.MathUtils.lerp(1, 0, range1 * 3);
    }


  }, [scrollContext, props.transition]);

  useGSAP(() => {


    if (!props.transition && firstLaunch) {
      setFirstLaunch(false);

      gsap.fromTo(caseTopContainer.current, {opacity: 0, duration: 0.5}, {opacity: 1, duration: 0.5})

      const title = new SplitText(caseTitle.current, {
        types: 'words, chars',
        lineClass: 'horizontal-anim'
      });

      gsap.from(title.chars, {
        opacity: 0,
        duration: 0.5,
        // delay: 0.3,
        stagger: 0.06
      }).then(() => {
        title.revert();
      });

      const scrollForMoreSplit = new SplitText(scrollForMore.current, {
        types: 'lines, words',
        lineClass: 'word-line'
      });
      gsap.from(scrollForMoreSplit.words, {
        y: '75%',
        duration: 1,
        // delay: 0.3,
        opacity: 0,
        stagger: 0.03
      }).then(() => {
        scrollForMoreSplit.revert();
      });


      for (let item of caseDescriptions.current) {
        if (!item) {
          return;
        }

        const split = new SplitText(item, {
          types: 'lines, words',
          lineClass: 'word-line'
        });
        gsap.from(split.words, {
          y: "75%",
          // skewX: "-3",
          // ease: 'none',
          opacity: 0,
          duration: 1.5,
          // delay: 0.5,
          stagger: 0.02,
        })
      }
    }

  }, [props.transition, firstLaunch]);

  return (
    <div ref={caseTopContainer} className='case_top'>
      <div className='case_info'>
        <h1 ref={caseTitle} className='case_title'>{CASESCONST[props.workIndex].name}</h1>
        {CASESCONST[props.workIndex].textInfo.map((item, index) => <p
          ref={el => caseDescriptions.current[index] = el}
          className={'case_info_text' + ' ' + 'case_info_text_' + index}
          key={index}>{item}</p>)}
      </div>
      <div ref={scrollForMore} className='scrollmore'>SCROLL FOR MORE</div>
    </div>
  );
};

const TopSectionOfNextPage = (props) => {

  const getNextCaseIndex = () => {
    let nextIndex;
    if (props.workIndex < WORKSCONST.length - 1) {
      nextIndex = props.workIndex + 1;
    } else {
      nextIndex = 0;
    }
    return nextIndex;
  }

  const caseTopContainer = useRef();
  const caseDescriptions = useRef([]);
  const caseTitle = useRef([]);
  const [nextCaseIndex, setNextCaseIndex] = useState(() => getNextCaseIndex());
  const [scrollContext] = useContext(ScrollContext);



  useEffect(() => {
    setTimeout(()=> {
      setNextCaseIndex(getNextCaseIndex());

    }, 500)
  }, [props.workIndex])

  useEffect(() => {
    const offset = (scrollContext.scrollTop + scrollContext.viewPortHeight) / scrollContext.scrollAll;

    const range = (from, distance) => {
      const start = from;
      const end = start + distance;
      return offset + 0.01 < start ? 0 : offset + 0.01 > end ? 1 : (offset + 0.01 - start) / (end - start);
    };




    const r5 = range(9 / 10, 1 / 10);

    caseTopContainer.current.style.opacity = THREE.MathUtils.lerp(0, 1, r5);


  }, [scrollContext, props.transition]);

  // useEffect()

  return (
    <div ref={caseTopContainer} className='case_top'>
      <div className='case_info'>
        <h2 ref={caseTitle} className='case_title'>{CASESCONST[nextCaseIndex].name}</h2>
        {CASESCONST[nextCaseIndex].textInfo.map((item, index) => <p
          ref={el => caseDescriptions.current[index] = el}
          className={'case_info_text' + ' ' + 'case_info_text_' + index}
          key={index}>{item}</p>)}
      </div>

    </div>
  );
};






const AboutClient = (props) => {

  const [scrollContext] = useContext(ScrollContext);
  const container = useRef();

  useEffect(() => {
    const offset = (scrollContext.scrollTop - (container.current.offsetTop + container.current.clientHeight * 0.5)) * -1;
    const offset2 = Math.max(0, Math.min(offset, scrollContext.viewPortHeight));
    const offset3 = offset2 / scrollContext.viewPortHeight;
    const opacity = Math.abs((Math.abs((offset3 - 0.5) * 2) - 1));
    container.current.style.opacity = (opacity - 0.3) * 2;
  }, [scrollContext]);

  const onMouseOver = () => {
    props.setCursorState((state) => state = 'cursor-hover');
  };

  const onMouseOut = () => {
    props.setCursorState((state) => state = '');
  };


  return (
    <div ref={container} className='about_container'
         style={{ top: props.mediaQuery !== 'phone' && props.mediaQuery !== 'phoneLandscape' ? `${window.innerHeight / 100 * 150 + 'px'}` : `${window.innerHeight / 100 * 140 + 'px'}` }}>
      <h3 className='segment_title'>About Client</h3>
      <div className='about_info'>
        <div
          style={(CASESCONST[props.workIndex].quote && (props.mediaQuery === 'desktop' || props.mediaQuery === 'laptop' || props.mediaQuery === 'iPad')) ? { width: '50%' } : null}>
          <div className='about_info_text'>
            {CASESCONST[props.workIndex].aboutClientInfo.map((item, index) => <p
              style={CASESCONST[props.workIndex].quote ? { width: '100%' } : null}
              key={index}>{item}</p>)}
          </div>
          <a onMouseOver={onMouseOver}
             onMouseOut={onMouseOut} className='link link--kale' href={CASESCONST[props.workIndex].link}
             target='_blank'>VISIT WEBSITE<img className='about_link_arrow' src='/images/arrow.svg' alt=''/>
          </a>
        </div>
        {CASESCONST[props.workIndex].quote ?
          <div className='about_quote'>{CASESCONST[props.workIndex].quote}</div> : null}
      </div>
    </div>
  );
};


const ImageComponent = (props) => {

  const image = useRef();
  const imageWrapper = useRef();
  const [imgFormat, setImgFormat] = useState('.webp');


  // const [scrollContext] = useContext(ScrollContext);

  useEffect(() => {
    let img = new Image();
    img.src = props.src + '.webp'
    img.alt = ''
    img.onload = () => {
      setImgFormat('.webp');
    }
    return () => {
      img.src = '';
    }
  }, [])

  // useEffect(() => {
  //   return;
  //   if (
  //     (imageWrapper.current.offsetTop > scrollContext.scrollTop + scrollContext.viewPortHeight) ||
  //     (imageWrapper.current.offsetTop + imageWrapper.current.clientHeight < scrollContext.scrollTop)
  //   ) {
  //     return;
  //   }
  //   const offset = (scrollContext.scrollTop - (image.current.offsetTop)) * -1;
  //   const offset2 = Math.abs((Math.min(offset, scrollContext.viewPortHeight) / scrollContext.viewPortHeight) - 1);
  //   const scale = (offset2 / 8) + 1;
  //   image.current.style.transform = `scale3d(${scale},${scale},1)`;
  // }, [scrollContext]);

  const onMouseOver = () => {
    props.setCursorState((state) => state = 'cursor-hover-case-link');
  };

  const onMouseOut = () => {
    props.setCursorState((state) => state = '');
  };

  return (
    <a onMouseOver={onMouseOver}
       onMouseOut={onMouseOut} className='' href={CASESCONST[props.workIndex].link} target='_blank'>
      <div ref={imageWrapper} className={props.className}>
        <img ref={image} draggable='false' width='100%' src={props.src + imgFormat} alt='' />
      </div>
    </a>
  );
};

const SegmentTitle = (props) => {
  const [scrollContext] = useContext(ScrollContext);
  const title = useRef();
  useEffect(() => {
    const offset = (scrollContext.scrollTop - (title.current.offsetTop + title.current.clientHeight * 0.5)) * -1;
    const offset2 = Math.max(0, Math.min(offset, scrollContext.viewPortHeight));
    const offset3 = offset2 / scrollContext.viewPortHeight;
    const opacity = Math.abs((Math.abs((offset3 - 0.5) * 2) - 1));
    title.current.style.opacity = opacity * 2;
  }, [scrollContext]);
  return (
    <h3 ref={title} className='segment_title'>{props.children}</h3>
  );
};


const AltraCaseHtml = (props) => {
  return (
    <>
      <Helmet>
        <title>Altra: Crafting an Interactive City for Coinspaid's MVP Launch</title>

        <meta name='description'
              content="Discover how Third Dimension created Altrai's immersive interactive city for Coinspaid's MVP launch. Explore our innovative design process that brought this digital metropolis to life."/>
      </Helmet>
      <SegmentTitle>Blocking</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/altra/5'} />
      </div>
      <SegmentTitle>3D Modelling</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/8'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/altra/9'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/altra/10'} />
      </div>
      <SegmentTitle>Ready Concepts + UI</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/altra/11'} />
        <div />
      </div>
    </>
  );
};

const ByteCaseHtml = (props) => {
  return (
    <>
      <Helmet>
        <title>Case Study: Revolutionizing Byte Trading with Immersive Scrollytelling</title>

        <meta name='description'
              content="Learn how Third Dimension Studio transformed Byte Trading's digital presence using immersive scrollytelling. Dive into our creative process and see how we pushed the boundaries of interactive storytelling."/>

      </Helmet>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/byte/1'} />
      </div>
      <SegmentTitle>3D modeling</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/byte/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/byte/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/byte/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/byte/5'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/byte/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/byte/7'} />
      </div>
      <SegmentTitle>Ready Concepts + UI</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/byte/8'} />
      </div>
    </>
  );
};

const HeadphonesCaseHtml = (props) => {
  return (
    <>

      <SegmentTitle>3D modeling</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/headphones/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/headphones/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/headphones/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/headphones/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/headphones/5'} />
      </div>
      <SegmentTitle>Ready concepts + UI</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/headphones/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/headphones/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/headphones/8'} />
      </div>
    </>
  );
};




const Video = (props) => {
  return (
    <div className='case_images_container'>
      <video className='video case_image case_image_big' playsInline={true}
             src={props.src}
             crossOrigin='Anonymous' autoPlay loop muted />
    </div>
  );
};

const AboutInfo = () => {
  const [scrollContext] = useContext(ScrollContext);
  const aboutInfo = useRef();
  useEffect(() => {
    const offset = (scrollContext.scrollTop - (aboutInfo.current.offsetTop + aboutInfo.current.clientHeight * 0.5)) * -1;
    const offset2 = Math.max(0, Math.min(offset, scrollContext.viewPortHeight));
    const offset3 = offset2 / scrollContext.viewPortHeight;
    const opacity = Math.abs((Math.abs((offset3 - 0.5) * 2) - 1));
    aboutInfo.current.style.opacity = opacity * 2;
  }, [scrollContext]);


  return (
    <div ref={aboutInfo} className='about_info'>
      <p className='additional_info additional_info_first'>
        "We were needed to tell about a technology company in the format of an interactive scene."
      </p>
      <p className='additional_info additional_info_second'>
        The design was supposed to be a stylistically related to mathematics and computing. The
        concept is based on the idea of a quantum computer simulating is trying to find patterns and
        unravel the algorithm.
      </p>
    </div>
  );
};

const CorvusCaseHtml = (props) => {

  return (
    <>

      <Helmet>
        <title>Corvus Research: Crafting an Innovative Presence for an AI Pioneer</title>

        <meta name='description'
              content="Learn how Third Dimension transformed Corvus Research's digital identity. Dive into our creative process of developing an innovative online presence for this leading AI company."/>

      </Helmet>

      <Video src={props.videoUrls[props.workIndex]} />
      <AboutInfo></AboutInfo>
      <SegmentTitle>References</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/corvus/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/corvus/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/corvus/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/corvus/4'} />
      </div>
      <SegmentTitle>Abstractions</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/corvus/5'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/corvus/6'} />
      </div>
      <SegmentTitle>Ready Concepts + UI</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/corvus/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/corvus/8'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/corvus/9'} />
      </div>
    </>
  );
};


const CityCaseHtml = (props) => {


  return (
    <>

      <Helmet>
        <title>Third Dimension 3D City: We Create Immersive 3D Websites</title>

        <meta name='description'
              content="Discover how Third Dimensio brings to life with Third Dimension 3D City. We specialize in creating immersive 3D websites that captivate audiences and transform digital experiences."/>

      </Helmet>

      <SegmentTitle>Blocking</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/city/1'}/>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little_square' src={'./images/cases/city/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little_square' src={'./images/cases/city/3'} />
      </div>
      <SegmentTitle>3D Modelling</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/city/4'}/>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/city/5'}/>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/city/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/city/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/city/8'} />
      </div>
      <SegmentTitle>Ready concepts + UI</SegmentTitle>
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/city/9'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/city/10'} />
      </div>
    </>
  );
};

const EmpathyLabsCaseHtml = (props) => {


  return (
    <>

      <Helmet>
        <title>Case Study: 3D Animated Landing Page for AI Development Service Provider by Third Dimension</title>

        <meta name='description'
              content="Discover how Third Dimension created an immersive 3D animated landing page for an AI development service provider. Explore the project's objectives, design process, and the innovative solutions that brought this vision to life."/>

      </Helmet>

      {/*<SegmentTitle>Blocking</SegmentTitle>*/}
      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/empathy/1'}/>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/empathy/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/empathy/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/empathy/4'}/>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/empathy/5'}/>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_little' src={'./images/cases/empathy/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/empathy/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex} className='case_image case_image_big' src={'./images/cases/empathy/8'} />
      </div>
    </>
  );
};

const AllInMotionCaseHtml = (props) => {
  return (
    <>

      <Helmet>
        <title>Behind the Scenes: Transforming All In Motion's Website with Animation and Innovation</title>

        <meta name='description'
              content="Go behind the scenes with Third Dimension to see how we transformed All In Motion's online presence. Discover our unique approach to integrating animation with a twist."/>

      </Helmet>

      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/allinmotion/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/5'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/allinmotion/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/8'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/allinmotion/9'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/10'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/allinmotion/11'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/allinmotion/12'} />
      </div>
    </>
  );
};

const HailstoneCaseHtml = (props) => {
  return (
    <>

      <Helmet>
        <title>Case Study: Immersive Landing Page for Web3 VC Studio Hailstone Labs by Third Dimension</title>

        <meta name='description'
              content="Discover how Third Dimension Studio crafted an immersive landing page for a Web3 venture capital studio. Explore our innovative design process and learn how we brought their vision to life with cutting-edge web technologies."/>

      </Helmet>

      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/hailstone/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/hailstone/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/hailstone/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/hailstone/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/hailstone/5'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/hailstone/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/hailstone/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/hailstone/8'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/hailstone/9'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/hailstone/10'} />
      </div>
    </>
  );
};

const PBMediaCaseHtml = (props) => {
  return (
    <>

      <Helmet>
        <title>Case Study: PB Media – Brand made with 3D Mascot, Animations, and Interactive Website by Third Dimension</title>

        <meta name='description'
              content="Explore how Third Dimension helped PB Media stand out with a custom 3D mascot, captivating animations, and an interactive website. Explore our creative process and innovative solutions that elevated their brand identity."/>

      </Helmet>

      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/pb/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/pb/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/pb/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/pb/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/pb/5'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/pb/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/pb/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/pb/8'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/pb/9'} />
      </div>
    </>
  );
};

const MillerCaseHtml = (props) => {



  return (
    <>

      <Helmet>
        <title>Case Study: Miller Panelling – Driving Emotions for a B2B Service Provider by Third Dimension</title>

        <meta name='description'
              content="Discover how Third Dimension Studio collaborated with Miller Panelling to evoke emotions in a B2B setting. Explore our innovative design strategies that enhanced their service offering and client engagement."/>

      </Helmet>

      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/miller/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/5'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/8'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/9'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/miller/10'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/11'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/12'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/13'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/miller/14'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/miller/15'} />
      </div>
    </>
  );
};

const SomvaiCaseHtml = (props) => {
  return (
    <>

      <Helmet>
        <title>Somvai: Launching a Brand-Focused Website for a Successful Kickstarter Campaign</title>

        <meta name='description'
              content="Discover how Third Dimension crafted a compelling brand-focused website for Somvai's Kickstarter campaign. Explore our strategies that boosted engagement and drove crowdfunding success."/>

      </Helmet>

      <div className='case_images_container'>
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/1'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/2'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/somvai/3'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/4'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/5'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/somvai/6'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/somvai/7'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/8'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/9'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/10'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/11'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/somvai/12'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/13'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_little' src={'./images/cases/somvai/14'} />
        <ImageComponent setCursorState={props.setCursorState} workIndex={props.workIndex}
                        className='case_image case_image_big' src={'./images/cases/somvai/15'} />
      </div>
    </>
  );
};


export default function CasesUI(props) {
  const [scrollContext, setScrollContext, scrollContainer, setScrollContainer] = useContext(ScrollContext);
  const scrollWrapperRef = useRef();
  const casePageWrapperRef = useRef();
  const location = useLocation();

  useEffect(() => {
    setScrollContainer(scrollWrapperRef.current);
    // scrollWrapperRef.current.scrollTop = 0;
    setPos();
  }, []);

  const setPos = useCallback(() => {
    setScrollContext({
      scrollTop: scrollWrapperRef.current.scrollTop,
      viewPortHeight: scrollWrapperRef.current.offsetHeight,
      scrollAll: scrollWrapperRef.current.scrollHeight
    });
  }, [setScrollContext]);

  useEffect(() => {
    // props.setTransition(true);
    if (props.switchPage) {
      gsap.to(casePageWrapperRef.current, { opacity: 0, duration: 1.5 });
    }
  }, [props.switchPage]);

  useEffect(() => {
    scrollWrapperRef.current.scrollTop = 0;
  }, [location]);

  const isCurrentDeviceMobile = useMemo(() => {
    const details = navigator.userAgent;
    const regexp = /android|iphone|kindle|ipad/i;
    return regexp.test(details);
  }, []);

  return (
    <>
      <div ref={casePageWrapperRef}>
        <TopSection transition={props.transition} workIndex={props.workIndex} />
        <TopSectionOfNextPage transition={props.transition} workIndex={props.workIndex} />

        <div
          ref={scrollWrapperRef}
          style={{ overflowY: props.transition ? 'hidden' : 'auto' }}
          onScroll={setPos}
          className='cases-scroll-wrapper'
        >
          <div className='cases-scroll-container'>
            <div className='cases_container'>
              <div className='cases_wrapper'>
                <AboutClient setCursorState={props.setCursorState} mediaQuery={props.mediaQuery} workIndex={props.workIndex} />

                {props.workIndex === 0 ? <EmpathyLabsCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}
                {props.workIndex === 1 ? <HailstoneCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}
                {props.workIndex === 2 ? <MillerCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}

                {props.workIndex === 3 ? <PBMediaCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}


                {props.workIndex === 4 ? <AllInMotionCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}

                {props.workIndex === 5 ? <ByteCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}


                {props.workIndex === 6 ? <SomvaiCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}

                {props.workIndex === 7 ? <AltraCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}
                {props.workIndex === 8 ? <CorvusCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} videoUrls={props.videoUrls} /> : null}

                {props.workIndex === 9 ? <CityCaseHtml setCursorState={props.setCursorState} workIndex={props.workIndex} /> : null}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}